import React from 'react';
import config from '../../config';
import { NamedLink } from '../../components';

import css from './ListingPage.module.css';

const SectionCategoryBar = props => {
  const { listing } = props;

  const title = listing?.attributes?.title || '';

  const subcategory = listing?.attributes?.publicData?.subcategory || [];

  if (subcategory.length === 0) { 
    return null
  }
  
  const getSearchQueries = () => {
    const subcategories = Array.isArray(subcategory) ? subcategory : [subcategory];

    let subCategoryObjects = subcategories
      .map(sub =>
        config.custom.filters
          .find(f => f.id === 'subcategory')
          .config.options?.find(o => o.key === sub)
      )
      .filter(Boolean);

    if (subCategoryObjects.length === 0) {
      subCategoryObjects = subcategories.map(sub => ({
        label: sub
          .split('-')
          .map(s => s.charAt(0).toUpperCase() + s.slice(1))
          .join(' '),
        category: null,
        key: sub,
      }));
    }

    let mainCategoryKeys = [
      ...new Set(subCategoryObjects.map(obj => obj.mainCategory || obj.category)),
    ].filter(Boolean);


    if (mainCategoryKeys.length === 0) {
      console.error('Categories not found');
    }

    let mainCategoryObjects = mainCategoryKeys
      .map(key =>
        config.custom.filters
          .find(f => f.id === 'category')
          .config.options?.find(o => o.key === key)
      )
      .filter(Boolean);

    if (mainCategoryObjects.length === 0) {
      mainCategoryObjects = mainCategoryKeys.map(key => ({
        label: key
          .split('-')
          .map(s => s.charAt(0).toUpperCase() + s.slice(1))
          .join(' '),
        key,
      }));
    }

    const queryList = [
      { query: '', label: 'Petspace', hasLink: true },
      ...(mainCategoryObjects.length
        ? [
            {
              query: `pub_category=${mainCategoryKeys.join(',')}`,
              label: mainCategoryObjects.map(o => o.label).join(', '),
              hasLink: mainCategoryKeys.some(Boolean),
            },
          ]
        : []),
      ...(subCategoryObjects.length
        ? [
            {
              query: `pub_category=${mainCategoryKeys.join(
                ','
              )}&pub_subcategory=${subcategories.join(',')}`,
              label: subCategoryObjects.map(o => o.label).join(', '),
              hasLink: mainCategoryKeys.some(Boolean) && subcategories.some(Boolean),
            },
          ]
        : []),
    ];

    return queryList;
  };

  return (
    <div className={css.categoryBar}>
      {React.Children.toArray(
        getSearchQueries().map(({ query, label, hasLink }, index, array) => (
          <>
            {hasLink ? (
              <NamedLink name="SearchPage" to={{ search: query }}>
                {label}
              </NamedLink>
            ) : (
              <span className={css.categoryBarTitle}>{label}</span>
            )}
            {index < array.length - 1 && <div className={css.categoryDivider}>/</div>}
          </>
        ))
      )}
      <div className={css.categoryDivider}>/</div>
      <div className={css.categoryBarTitle}>{title}</div>
    </div>
  );
};

export default SectionCategoryBar;
